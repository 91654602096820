// Variable overrides
@import url("https://fonts.googleapis.com/css2?family=Roboto:ital,wght@0,100;0,300;0,400;0,500;0,700;0,900;1,100;1,300;1,400;1,500;1,700;1,900&display=swap");

$theme-colors: (
	primary: #121a31,
	secondary: #3d5371,
	dark: #283c58,
	_canceled: #d82525,
	_approved: #efb729,
	_pending: #858e9b,
	_finished: #21419f,
	_default_status: #858e9b,
	_pix: #2894FF,
	_accentBlue: #0052CC,
	_blueOcean: #DEEBFF,
	_clearGreen: #C4EEDB,
	_accentGreen: #0C8450,
	CANCELED: #d82525,
	APPROVED: #efb729,
	EXEC_PENDING: #858e9b,
	OUTF_PENDING: #858e9b,
	FINISHED: #21419f,

);

.badge-_approved,
.badge-_canceled,
.badge-_pending,
.badge-_finished,
.badge-_default_status {
	color: white !important;
}

$sidebar-dark-nav-link-hover-color: #b4b5b6 !default;
$sidebar-dark-nav-link-color: #b4b5b6 !default;
$sidebar-dark-nav-link-hover-bg: theme-color("primary") !default;
$sidebar-dark-nav-link-hover-icon-color: #b4b5b6 !default;
$sidebar-dark-nav-link-hover-icon-color: #b4b5b6 !default;

$font-family-base: "Roboto", sans-serif;
