// Here you can add other styles

$body-bg: #2b2b2b;
$body-color: #2b2b2b;

.background-hangarar {
	height: 100%;
	width: 100%;
	background: linear-gradient(to right, #131b31, #3a4c64);
}

.c-sidebar-nav-link {
	font-weight: 700 !important;
	font-size: 12pt !important;
}

.c-sidebar-nav-icon{
	height: 1.5rem !important;
}

.c-sidebar {
	color: #fff !important;
	background: linear-gradient(180deg, #394b63, #121a31) !important;
}

.danger-text {
	color: theme-color("danger");
}

input[type="number"]::-webkit-inner-spin-button,
input[type="number"]::-webkit-outer-spin-button {
	-webkit-appearance: none;
	-moz-appearance: none;
	appearance: none;
	margin: 0;
}
